<template>
  <div class="toolbar">
    <VToolbar
      app
      :height="$mq === 'xs' ? '70px' : $mq === 'sm' ? '100px' : '120px'"
      v-bind="{ ...$attrs, ...$props }"
    >
      <BaseLink :to="{ path: '/' }" class="nav-logo--link">
        <img
          :class="['nav-logo', $mq, { 'ml-2': ['xs', 'sm'].includes($mq) }]"
          :src="navBarLogo"
          :alt="`${getSiteTitle} Logo`"
        />
      </BaseLink>
      <VFlex v-if="navRoutesLeft.length" class="nav-left hidden-sm-and-down">
        <nav>
          <NavBarRoutes :routes="navRoutesLeft" />
        </nav>
      </VFlex>
      <VFlex
        v-if="navRoutesCenter.length"
        class="nav-center text-xs-center hidden-sm-and-down grow"
      >
        <nav>
          <span class="nav-route">
            <VMenu open-on-hover bottom offset-y content-class="elevation-2">
              <template #activator="{ on }">
                <a color="transparent" v-on="on">
                  Menus
                </a>
              </template>

              <VList class="bubs-purple">
                <WithLocationSummaries v-slot="{ locations }">
                  <div class="locations-dropdown">
                    <VListTile
                      v-for="(loc, index) in locations"
                      :key="index"
                      :to="`/locations/${loc.slug}`"
                    >
                      <VListTileTitle
                        class="poppins--font font-weight-bold white--text"
                        >{{ loc.title }}</VListTileTitle
                      >
                    </VListTile>
                  </div>
                </WithLocationSummaries>
              </VList>
            </VMenu>
          </span>
          <NavBarRoutes :routes="navRoutesCenter" />
        </nav>
      </VFlex>
      <VFlex
        class="nav-right"
        text-sm-right
        :grow="['xs', 'sm', 'md'].includes($mq)"
      >
        <nav>
          <VLayout row wrap text-xs-right justify-end align-center>
            <NavBarRoutes :routes="navRoutesRight" />
            <VFlex
              v-for="channel of getSocialChannels"
              :key="channel.name"
              class="nav-route social-icon text-xs-center mx-0 mb-0 mt-1 hidden-xs-only"
            >
              <a :href="channel.url" target="_blank" class="py-2 px-2 mr-2">
                <img
                  :src="
                    require(`@assets/images/icon-${channel.name}-white.svg`)
                  "
                  :alt="channel.name"
                />
              </a>
            </VFlex>

            <VFlex class="order-now-btn--wrap" shrink>
              <BaseButton
                small
                :class="[
                  'nav-menu-cta black--text',
                  $mq === 'sm' ? 'mr-2' : $mq === 'md' ? 'mr-2' : 'mr-0',
                ]"
                color="bubs-green"
                @click="isOrderDialogOpen = true"
                >Order Online</BaseButton
              >
            </VFlex>
            <BaseButton
              icon
              flat
              color="transparent"
              :text-color="menuBtnColor"
              large
              md-icon="menu"
              aria-label="Toggle Mobile Menu"
              class="hidden-md-and-up mr-0"
              @click="$store.commit('nav/TOGGLE_DRAWER')"
            ></BaseButton>
          </VLayout>
        </nav>
      </VFlex>
    </VToolbar>
  </div>
</template>

<script>
import { authComputed } from '@store/helpers'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import NavBarRoutes from '@components/NavBarRoutes'

import getContactDetail from '@utils/get-contact-detail'
import getSiteAsset from '@utils/get-site-asset'
import formatPhone from '@utils/format-phone'

import WithLocationSummaries from '@dataProviders/WithLocationSummaries'

export default {
  name: 'BubsNavBar',
  components: { WithLocationSummaries, NavBarRoutes },
  props: {
    menuBtnColor: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...authComputed,
    ...mapGetters('site', [
      'getSiteTitle',
      'getSocialChannels',
      'getSiteId',
      'getSiteNavMenu',
    ]),
    ...mapFields('nav', ['isOrderDialogOpen']),
    navRoutesLeft() {
      return this.getSiteNavMenu.filter(
        (r) => !r.position || r.position === 'left'
      )
    }, // navRoutesLeft

    navRoutesCenter() {
      return this.getSiteNavMenu.filter(
        (r) => r.position || r.position === 'center'
      )
    }, // navRoutesCenter

    navRoutesRight() {
      return this.getSiteNavMenu.filter(
        (r) => r.position && r.position === 'right'
      )
    }, // navRoutesRight
    navBarLogo() {
      return getSiteAsset(`site-${this.getSiteId}-navbar-logo.png`)
    }, // navBarLogo
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
  },
}
</script>

<style lang="scss">
@use "sass:math";
@import '@design';
@import '@styleMixins';

.toolbar .theme--light.v-toolbar {
  z-index: 3;
  &,
  .v-toolbar__content {
    > * {
      z-index: 1;
    }
  }
  .v-toolbar__content {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (max-width: 414px) {
      padding: 0 5px;
    }
  }
}

.nav-left,
.nav-center,
.nav-right {
  flex: 0 1 auto;
}

.nav-route a,
.nav-menu-cta .v-btn__content {
  @extend .poppins--font;
  font-weight: bold;
  color: $color-bubs-charcoal;
}

.nav-menu-cta {
  @include padding-lr(1.5em);

  .v-btn__content {
    font-size: 1.1rem;
  }
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-decoration: none;
  text-transform: uppercase;
  a {
    color: white;
    text-decoration: none;
    letter-spacing: 0.074em !important;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: white;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid white;
  }
} // nav-route

.nav-logo--link {
  line-height: 0;
}
.nav-logo {
  max-width: 130px;
  cursor: pointer;
  &.lg,
  &.md,
  &.sm {
    max-width: 100px;
  }
  &.xs {
    max-width: 60px;
  }
}

.order-now-btn--wrap {
  @media only screen and (max-width: 340px) {
    display: none;
    visibility: hidden;
  }
}
</style>
